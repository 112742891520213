<template>
  <div>
    <h3 class="flix-html-h3">{{ $tc('message.calendar', 2) }}: {{ $tc('message.color', 1) }}</h3>
    <!-- <label class="flix-html-label">{{ $t('message.accent_color') }}</label> -->
    <flixColorpicker :open="false" :value="styling.accent" :callback="function (value) { styling.accent = value; setValue() }"/>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    callback: Function,
    data: Object
  },
  data () {
    return {
      color: 'blue',
      styling: this.data.styling
    }
  },
  methods: {
    getValue () {
      if (typeof this.data.styling === 'object') {
        this.styling = Object.assign(this.styling, this.data.styling)
      }
    },
    setValue () {
      this.callback(this.styling)
    }
  },
  mounted () {
    this.getValue()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
